.add-website-form {
  width: 100%;
  height: 100%;
  position: relative;
  &__button {
    position: fixed;
    top: 71px;
    right: 32px;
    z-index: 999999999;
    background-color: transparent;
    padding-inline: 20px 16px;
    &--desktop {
      @media (max-width: 1000px) {
        display: none;
      }
      @media (min-width: 1500px) {
        right: 62px;
      }
    }
    &--mobile {
      display: none;
      @media (max-width: 1000px) {
        display: flex;
        position: absolute;
        top: -30px;
        right: 0;
        left: unset;
      }
    }
    .btn__icon {
      margin: 0;
      margin-left: 10px;
      margin-top: 8px;
    }
    svg {
      width: 10px;
    }
  }
  &__wrapper {
    // max-height: 400px;
    // overflow-y: auto;
    padding-bottom: 20px;
  }
  &__below-acceptance {
    margin-top: 30px;
  }
  &__modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99999999;
    height: 100%;
    background-color: #f2f2f2;
    display: grid;
    align-items: center;
    // align-items: safe center;
    justify-content: center;
    padding: 0 16px;
    overflow: auto;
    @media (max-width: 768px) {
      align-items: unset;
      // height: calc(100% - 69px);
      // margin-top: 69px;
      overflow: auto;
      padding-bottom: 80px;
      padding-top: 80px;
    }
  }
  &__form {
    width: 360px;
    @media (max-width: 768px) {
      width: 100%;
      max-width: 360px;
    }
  }
  &__radio {
    border-radius: 100px;
    background: #fff;
    display: flex;
    padding: 4px 7px;
    align-items: center;
    width: 100%;
    margin-top: 30px;
  }
  &__radio-item {
    width: 100%;
    display: flex;
    height: 32px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 50px;
    transition: 0.3s;
    color: var(--Niebieski-500, #352fee);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    cursor: pointer;
    .svg {
      transition: 0.3s;
      opacity: 0;
      height: 18px;
      width: 18px;
    }
    &--active {
      background: var(--Niebieski-500, #352fee);
      color: #fff;
      cursor: default;
      .svg {
        opacity: 1;
      }
    }
  }
  &__field-group {
    display: flex;
    gap: 16px;
    .form__field {
      width: 100%;
    }
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
  &__tooltip-wrapper {
    position: relative;
    .add-website-form__packet-tooltip {
      top: -60px;

      @media (max-width: 768px) {
        margin-top: 20px;
        top: 0;
        .tooltip-svg {
          display: block;
        }
      }
      .add-website-form__tooltip-content {
        max-width: 234px;
        @media (max-width: 768px) {
          max-width: unset;
        }
        &--left {
          @media (max-width: 768px) {
            max-width: unset;
          }
          max-width: 241px;
        }
        .tooltip-svg {
          right: -30px;
        }
      }
    }
  }
  &__you-can-cancel {
    color: var(--szary-700, #404040);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 20px;
  }
  &__guide {
    text-decoration: underline;
    cursor: pointer;
    color: inherit;
  }
  &__title {
    color: var(--czarny-500, #111);

    font-size: 24px;
    font-weight: 900;
    line-height: 30px;
  }
  &__error {
    color: var(--czerwony-500, #e20d0d);
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    margin-top: 8px;
    a {
      color: var(--czerwony-500, #e20d0d);
      text-decoration: underline;
    }
  }
  &__subtitle {
    color: var(--szary-700, #404040);

    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin-top: 22px;
    &--second {
      margin-top: 10px;
    }
  }
  .form__field .loader {
    bottom: unset;
    top: 43px;
    right: 16px;
  }

  .without-margin.form__field--password .form__field--label-error {
    margin-top: 8px;
  }
  &__field {
    border-radius: 5px;
    background: #fff;
  }
  &__select-packet {
    margin-top: 45px;
    margin-bottom: 54px;
    h4 {
      color: var(--czarny-500, #111);
      font-size: 14px;
      font-weight: 700;
      line-height: 24px;
    }
  }
  &__password-validation {
    overflow: hidden;
    transition: 0.3s;
    max-height: 0;
    margin-bottom: 0;
    padding-top: 0;
    &--show {
      padding-top: 23px;
      max-height: 99px;
    }
  }
  &__password {
    margin-bottom: 40px;
  }
  &__nip {
    display: flex;
    gap: 14px;
    .form__field {
      margin: 0;
    }
    .form__field--select {
      width: 95px;
      min-width: 95px;
    }
    .react-select__indicator-separator {
      display: none;
    }
    div .react-select__control {
      margin: 0;
      height: 48px;
      border-radius: 5px;
    }
    .react-select__value-container {
      height: 48px;
      color: var(--szary-500, #808080);
      font-size: 14px;
      line-height: 24px;
    }
    .form__field--text {
      width: 100%;
    }
    .select__option-container {
      color: var(--szary-500, #808080);
      font-size: 14px;
      line-height: 24px;
      svg {
        width: 14px;
      }
    }
    .react-select__option--is-selected {
      .select__option-container {
        color: white;
      }
    }
  }
  &__post-code {
    display: flex;
    align-items: flex-end;
    gap: 14px;
    margin-bottom: 36px;
    .form__field {
      width: 100%;
      &:first-child {
        max-width: 110px;
      }
    }
  }
  &__cards {
    display: flex;
    gap: 8px;
    margin-bottom: 30px;
    .form__field {
      width: 100%;
      margin: 0;
    }
    .StripeElement {
      height: 48px;
      padding: 0;
      padding-inline: 15px;
      display: flex;
      align-items: center;
      border: none;

      .__PrivateStripeElement {
        width: 100%;
      }
    }
  }
  .StripeElement {
    border: none;
    cursor: text;
    padding: 12px 15px;
    margin-bottom: 8px;
    iframe {
      width: calc(100% + 10px) !important;
      margin: 0px !important;
      display: block !important;
    }
  }
  .myData__stripe--hideName .StripeElement {
    padding: 12px 0px;
    margin-top: -88px !important;
  }
  &__first_payment {
    color: var(--czarny-500, #111);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 50px;
    b {
      font-weight: 700;
    }
  }
  &__packet {
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--czarny-500, #111);
    font-size: 16px;
    font-weight: 900;
    border-radius: 5px;
    background: #fff;
    transition: 0.3s;
    padding-inline: 32px 16px;
    margin-top: 8px;
    cursor: pointer;
    position: relative;
    @media (max-width: 768px) {
      flex-direction: column;
      height: unset;
      max-height: 72px;
      padding-inline: 0;
      background: unset;
      &-card {
        border-radius: 5px;
        padding-inline: 32px 16px;
        background: #fff;
      }
    }
    &-card {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 70px;
      min-height: 70px;
    }
    &--active {
      background: #3832ec;
      color: #fff;
      @media (max-width: 768px) {
        max-height: 400px;
        background: unset;
        .add-website-form__packet-card {
          background: #3832ec;
          color: #fff;
        }
      }
      .add-website-form__packet-right {
        color: #fff;
      }
      div .svg--accept {
        opacity: 1;
      }
    }
    // &--disabled {
    // }
    &-left {
      display: flex;
      gap: 24px;
      align-items: center;
      svg {
        width: 40px;
        height: 40px;
      }
      .svg--packets-premium {
        height: 47px;
      }
    }
    &-right {
      display: flex;
      align-items: center;
      color: var(--czarny-500, #111);
      text-align: right;
      font-size: 28px;
      font-weight: 900;
      line-height: 28px;
      transition: 0.3s;
      span {
        display: flex;
        flex-direction: column;
        margin-left: 8px;
        p:first-child {
          font-size: 14px;
          line-height: 14px;
        }
        p:last-child {
          color: var(--szary-400, #a6a6a6);
          font-size: 10px;
          line-height: 14px;
        }
      }
      .svg--accept {
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        height: 24px;
        width: 24px;
        margin-left: 16px;
      }
    }
  }
  &__payment-method-button {
    display: flex;
    width: 147px;
    height: 32px;
    padding: 9px 0px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 1.5px solid var(--czarny-400, #262626);
    color: var(--czarny-400, #262626);
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    position: absolute;
    right: 0;
    transition: 0.3s;
    transition-property: opacity;
    cursor: pointer;
    opacity: 0;
    pointer-events: none;
    &--active {
      opacity: 1;
      pointer-events: all;
      z-index: 2;
      top: -15px;
    }
    &--opened {
      top: -8px;
    }
  }
  div &__payment-method-label {
    margin-bottom: 12px;
    label {
      margin-bottom: 12px !important  ;
    }
  }
  &__payment-method-button-wrapper {
    position: relative;
    margin-top: 50px;
  }
  &__payment-method-error {
    max-height: 0;
    transition: 0.3s;
    color: var(--czerwony-500, #e20d0d);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    overflow: hidden;
    &--show {
      max-height: 20px;
      margin-bottom: 32px;
      margin-top: 4px;
    }
  }
  &__payment-method-error-wrapper {
    overflow: hidden;
  }
  &__payment-method {
    border-radius: 6px;
    background: #fff;
    display: flex;
    height: 56px;
    padding: 23px 30px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    color: var(--czarny-500, #111);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 200% */
    cursor: pointer;
    border: 2px solid #fff;
    transition: 0.3s;
    .svg--credit-card {
      path {
        transition: 0.3s;
        fill: #111111;
      }
    }
    div {
      display: flex;
      align-items: center;
      gap: 8px;
    }
    .svg--accept {
      height: 16px;
      width: 16px;
      transition: 0.3s;
      opacity: 0;
      .b {
        stroke: #3832ec;
      }
    }
    &--active {
      background: var(--niebieski-500, #352fee);
      color: #fff;
      border: 2px solid var(--niebieski-500, #352fee);
      .svg--credit-card {
        path {
          fill: #fff;
        }
      }
      .svg--accept {
        opacity: 1;
      }
    }

    &--stroke {
      border-radius: 6px;
      border: 2px solid var(--niebieski-300, #908cff);
      background: #fff;
    }
  }
  &__packet-tooltip {
    position: absolute;
    left: calc(100% + 24px);
    display: flex;
    align-items: center;
    gap: 16px;
    opacity: 0;
    pointer-events: none;
    &--active {
      transition: opacity 0.3s ease-in-out;
      opacity: 1;
      pointer-events: all;
    }
    &--left {
      left: auto;
      right: calc(100% + 24px);
      @media (min-width: 768px) {
        top: -100px !important;
      }
      .tooltip__arrow {
        transform: rotateY(180deg);
      }
      h4 {
        font-size: 12px;
        font-style: normal;
        font-weight: 700 !important;
        line-height: normal;
        display: flex;
        gap: 4px;
      }
    }
    @media (max-width: 768px) {
      position: relative;
      left: 0;
      width: 100%;
      margin-top: 8px;
      > svg {
        display: none;
      }
      .tooltip-svg {
        display: none;
      }
    }
  }
  &__tooltip-strength {
    font-size: 12px;
    font-style: normal;
    font-weight: 700 !important;
    line-height: normal;
    transition: 0.3s;
    &--weak {
      color: #e20d0d !important;
    }
    &--medium {
      color: #f29225 !important;
    }
    &--strong {
      color: #1aaf54 !important;
    }
  }
  &__tooltip-content {
    border-radius: 16px;
    position: relative;
    background: #fff;
    display: flex;
    width: 359px;
    padding: 20px;
    flex-direction: column;
    gap: 8px;
    @media (max-width: 768px) {
      width: 100%;
    }
    h4 {
      color: var(--czarny-500, #111);
      font-size: 12px;
      font-weight: 900;
    }
    .tooltip-svg {
      position: absolute;
      top: -15px;
      right: 10px;
      &--ftp {
        top: -15px;
        right: -20px;
      }
      &--corgi-head {
        top: -36px;
      }
    }
    p {
      color: var(--czarny-300, #333);
      font-size: 12px;
      font-weight: 500;
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  &__subscription {
    color: var(--Szary-700, #404040);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    margin-bottom: 40px;
    margin-top: -24px;
    b {
      font-weight: 700;
    }
  }
}
.step {
  font-weight: 700;
  line-height: 36px;
  color: #000;
  font-size: 14px;
  &__last {
    color: var(--szary-500, #808080);
  }
}
