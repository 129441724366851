@import './_reset';
@import './_variables';
@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,700,900|Raleway:400,500,700,900');
@import url('https://fonts.googleapis.com/css?family=Lato:400,500,700,900|Raleway:400,500,700,900');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@600;700&display=swap');

.CardField-expiry {
  display: none !important;
}
@keyframes slide1 {
  0%,
  100% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(0, 20px);
  }
}
.form__field--password input {
  padding-right: 30px !important;
}
.test-class {
  display: none;
}
.click-disabled {
  pointer-events: none;
}
.click-disabled-desktop {
  @media (min-width: 1024px) {
    pointer-events: none;
  }
}
.button {
  scroll-behavior: smooth;
}
a {
  text-decoration: none;
}
html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}
.main-wrapper {
  overflow-x: hidden;
  background-color: #f1f1f1;
}

.webinar {
  .banner {
    z-index: 999999;
  }
}

.home-page {
  .banner {
    // z-index: 999999;
    // max-height: 00px;
    @media (max-width: 900px) {
      flex-direction: column-reverse;
    }
    @media (max-width: 1000px) {
      margin-top: 100px;
    }
    @include lg {
      margin-top: 110px;
    }
    @include xl {
      margin-top: 100px;
    }
  }
}
body {
  line-height: 1.5;
  font-size: 16px;
  // font-family: 'Montserrat', sans-serif;
  font-family: $fontPoppins;
}
.blurred {
  filter: blur(6px) brightness(0.8);
  // opacity: 0.5;
  // margin: -20px;
  .sidebar {
    height: 100% !important;
  }
}

.h1view {
  display: flex;
  justify-content: center;
  margin: 100px 0;
  h1 {
    text-transform: uppercase;
    font-weight: $weight__bold;
    font-size: 32px;
  }
}
.section--background {
  position: absolute;
  width: 100%;
  // top: 250px;
  top: 0px;
  left: 0;
  &--top250 {
    top: 250px;
  }
  @media (max-width: 1024px) {
    top: -80px;
  }
  @include md {
    // right: -60px;
    // top: 20px;
  }
  @include lg {
    // right: -10px;
  }
  img {
    @media (min-width: 260px) {
      width: 400%;
      margin-left: -600px;
      margin-top: 220px;
    }
    @media (min-width: 300px) {
      width: 400%;
      margin-left: -660px;
      margin-top: 220px;
    }
    @media (min-width: 350px) {
      width: 400%;
      margin-left: -738px;
      margin-top: 200px;
    }
    @media (min-width: 400px) {
      margin-top: 230px;
      width: 240%;
      margin-left: -418px;
    }
    @media (min-width: 456px) {
      margin-top: 230px;
    }
    @media (min-width: 561px) {
      display: block;
      margin-left: -292px;
      margin-top: 8px;
      width: 182%;
    }
    @include md {
      width: calc(100% + 8px);
      margin: 0;
    }
  }
  &--shield {
    position: absolute;
    top: 0;
    right: 0;
    @media (min-width: 2000px) {
      right: 9%;
    }
  }
  &-dark {
    width: calc(100% + 16px);
    left: -8px;
    background: var(--czarny-300, #2c2c2c);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    height: calc(100% + 14px);
    @media (max-width: 1000px) {
      height: calc(100% + 94px);
      @media (max-width: 756px) {
        img {
          margin-top: -180px;
        }
      }
    }
  }
}

.webinarPageHeader {
  height: 900px;
  @media (max-width: 1023px) {
    height: 760px;
  }
}

.section__wrapper {
  display: flex;
  justify-content: center;
  position: relative;
  max-width: 100%;
  // width: 100%;

  .corgiProBono {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 1500px;
    gap: 80px;
    margin-left: 200px;
    font-size: 20px;
    padding-bottom: 70px;
    padding-top: 50px;

    @media (max-width: 1441px) {
      width: 1200px;
      gap: 50px;
    }

    @media (max-width: 1023px) {
      display: flex;
      flex-direction: column;
      margin: auto;
      text-align: center;
      width: 60%;
      padding-top: unset;
    }

    .proBonoSection {
      line-height: 76.5px;
      padding-bottom: 20px;

      @media (max-width: 1441px) {
        font-size: 35px;
        line-height: 50px;
        width: 370px;
      }

      @media (max-width: 1023px) {
        font-size: 25px;
        width: 259px;
        line-height: 27px;
      }
    }

    &__corgiPremium {
      position: relative;
      right: 50px;
      bottom: 50px;

      @media (max-width: 1441px) {
        width: 250px;
      }

      @media (max-width: 1023px) {
        width: 190px;
        position: unset;
      }
    }

    &__corgiPaws {
      transform: rotate(20deg);
      position: relative;
      top: 50px;

      @media (max-width: 1441px) {
        width: 300px;
      }
    }

    &__content {
      p {
        opacity: 0.71;
        @media (max-width: 1023px) {
          width: 293px;
          font-size: 14px;
          line-height: 21px;
        }
      }

      &--link {
        text-decoration: underline;
        font-weight: $weight__bold;
        cursor: pointer;
      }
    }
  }

  .securityInfo {
    position: relative;
    bottom: 280px;
  }

  .corgiNotWoof {
    position: relative;
    top: -100px;
  }

  .securityStats {
    width: 920px;
    @media (max-width: 1023px) {
      width: 85%;
      height: 1000px;
    }
  }

  &--blue {
    background-color: #352fee;
    position: absolute;
    width: 201%;
    height: 110%;
    transform: rotate(8deg);
    z-index: 1;
    overflow: hidden;

    @media (max-width: 1023px) {
      height: 100%;
    }

    &-large {
      @media (max-width: 1023px) {
        width: 280%;
      }
    }
  }

  &--white {
    background-color: #fff;
    position: absolute;
    width: 201%;
    height: 110%;
    transform: rotate(8deg);
    z-index: 1;
    overflow: hidden;

    @media (max-width: 1023px) {
      height: 100%;
    }

    &-large {
      @media (max-width: 1023px) {
        width: 280%;
      }
    }
  }

  &--webinarBlue {
    background-color: #352fee;
    position: absolute;
    width: 201%;
    height: 100%;
    transform: rotate(8deg);
    z-index: 1;
    overflow: hidden;

    @media (max-width: 1023px) {
      height: 100%;
    }
  }

  &--gray {
    position: absolute;
    bottom: -200px;
    max-height: 500px;
    height: 100%;
    width: 100%;
    background-color: #f1f1f1;
  }
  &-margin {
    margin: 120px 0 160px;
    @include lg {
      margin: 220px 0;
    }
    .section {
      flex-direction: column !important;
    }
  }
  &_content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .img-ceo {
    width: 397px;
    height: 397px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: -532px;
    position: relative;
    right: 200px;
    top: 60px;
    @media (min-width: 1600px) {
      right: 270px;
    }
    @media (max-width: 1023px) {
      position: relative;
      top: -100px;
      right: 0;
      width: 197px;
      height: 197px;
    }
  }

  .ceoInfoWrapper {
    padding-top: 280px;
    display: flex;
    flex: 2;
    flex-direction: column;
    justify-content: space-between;
    gap: 100px;

    .baby-corgi {
      width: 124px;
      margin: auto;
    }

    .ceo-wrapper {
      display: flex;
      justify-content: flex-start;

      img {
        width: 397px;
      }

      &--box {
        width: 550px;
        display: flex;
        flex-direction: column;
        gap: 50px;

        .ceo-info {
          opacity: 0.7;
          .ceoName {
            font-size: inherit;
            padding: 0;
            margin: 0;
            width: unset;
            display: unset;
          }
        }
      }
    }

    .webinar-info {
      display: flex;
      justify-content: flex-end;

      &--box {
        width: 550px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        p {
          opacity: 0.7;
        }

        .button-wrapper {
          margin-top: 50px;
          display: flex;
          justify-content: center;
        }
      }
    }
    @media (max-width: 1023px) {
      display: flex;
      justify-content: center;
      width: 55%;
      padding-top: 520px;
      gap: 100px;

      .ceo-wrapper {
        text-align: center;

        &--box {
          width: 550px;
          display: flex;
          flex-direction: column;
          gap: 50px;

          .ceo-info {
            opacity: 0.7;
          }
        }
      }

      .webinar-info {
        display: flex;
        justify-content: flex-end;
        text-align: center;

        &--box {
          width: 550px;
          display: flex;
          flex-direction: column;
          gap: 20px;

          p {
            opacity: 0.7;
          }

          .button-wrapper {
            margin-top: 50px;
            display: flex;
            justify-content: center;
          }
        }
      }
    }
  }

  .summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 80px;
    margin-bottom: 100px;

    img {
      width: 400px;
    }

    .signUp {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 40px;
      justify-content: space-between;
      .bold {
        font-weight: $weight__bold !important;
      }
    }

    .button {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    @media (max-width: 1023px) {
      width: 80%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 80px;
      margin-bottom: 100px;

      img {
        width: 280px;
      }

      .common-errors {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 20px;
      }

      .signUp {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 40px;
        justify-content: space-between;
        text-align: center;
      }

      .button {
        display: flex;
        justify-content: center;
        width: 100%;
      }
    }
  }
}
.section {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 26px;
  margin-top: 80px;
  z-index: 1;
  @media (min-width: 900px) {
    flex-direction: row;
    // justify-content: space-between;
  }
  @include md {
    padding: 0 80px;
  }
  @include lg {
    padding: 0;
  }

  &--gray {
    background-color: #f2f2f2;
  }

  &--cut-bottom {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 83%);
    @media (max-width: 1000px) {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 92%);
    }
  }
  &--logo {
    max-width: 170px;
    max-height: 170px;
    a {
      outline: none;
    }
    @media (min-width: 401px) {
      padding: 0;
      max-width: 200px;
      object-fit: contain;
      max-height: 200px;
    }
    @include lg {
      max-width: 250px;
      max-height: 500px;
    }
    @include xl {
      max-width: 330px;
    }
  }
  &__l {
    display: flex;
    justify-content: center;

    @media (max-width: 1023px) {
      display: flex;
      flex-wrap: wrap;
      svg {
        position: relative;
        bottom: 100px;
        width: 180px;
      }
    }
  }
  &__r {
    margin-top: 50px;
    text-align: right;
    max-width: 730px;

    .btn--wrapper {
      display: flex;
      justify-content: flex-start;
      margin-top: 40px;
    }
  }

  &__r--webinar {
    margin-top: 50px;
    text-align: right;
    @media (max-width: 500px) {
      margin-top: 0;
    }
    .btn--wrapper {
      display: flex;
      justify-content: flex-end;
      margin-top: 30px;
    }
    @media (max-width: 1023px) {
      position: relative;
      bottom: 100px;
    }
  }

  &__r--dogs {
    @media (max-width: 320px) {
      margin-top: -190px;
    }
    margin-top: -120px;
    @include sm {
      margin-top: -100px;
    }
  }
  &__c {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -164px;
    .img {
      height: 238px;
      margin-bottom: 40px;
      @media (max-width: 769px) {
        height: 151px;
      }
    }

    .corgiWoof {
      position: relative;
      top: 20px;
      height: 200px;
      @media (max-width: 1023px) {
        margin-top: 250px;
      }
    }

    .btn {
      margin-top: 100px;
      @include md {
        margin: 52px 0;
      }
      @include lg {
        font-size: 20px;
      }
    }
  }

  &__i {
    display: flex;
    flex-direction: column;
    width: 70%;
    margin: 100px auto;
    gap: 50px;

    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .subRow {
        display: flex;
        gap: 5px;
      }

      .tilesGroup {
        width: 30%;
        display: flex;
        justify-content: flex-end;
        gap: 30px;
      }

      .text {
        width: 60%;
        font-weight: $weight__bold;
        color: white;
      }

      .plainText {
        width: 60%;
        color: white;
      }

      .webinarInfo {
        margin-top: 20px;
        .bold {
          font-weight: $weight__bold !important;
        }
      }

      .btn--wrapper {
        display: flex;
        justify-content: center;
      }

      .tile {
        width: 47px;
        height: 64px;
        border-radius: 6px;
        font-size: 35px;
        text-align: center;
        padding-top: 12px;
        font-weight: $weight__bold;
        background: linear-gradient(to top, rgb(154, 130, 226) 50%, rgb(210, 191, 240) 50%);
      }

      @media (max-width: 1023px) {
        display: flex;
        flex-direction: column;
        gap: 20px;
        text-align: center;
        justify-content: space-between;
        align-items: center;

        .tilesGroup {
          width: 100%;
          display: flex;
          justify-content: center;
          gap: 30px;
        }

        .subRow {
          display: flex;
          gap: 5px;
        }

        .text {
          width: 100%;
          font-weight: $weight__bold;
          color: white;
        }

        .plainText {
          width: 100%;
          color: white;
        }

        .webinarInfo {
          width: 100%;
        }

        .btn--wrapper {
          display: flex;
          justify-content: center;
        }

        .tile {
          width: 34px;
          height: 48px;
          border-radius: 6px;
          font-size: 30px;
          text-align: center;
          padding-top: 5px;
          font-weight: $weight__bold;
          background: linear-gradient(to top, rgb(154, 130, 226) 50%, rgb(210, 191, 240) 50%);
        }
      }
    }
  }

  &--header {
    max-width: 300px;
    @include md {
      max-width: 300px;
      width: 100%;
      margin-top: 90px;
    }
    @include lg {
      max-width: 500px;
    }
    @include xl {
      max-width: 600px;
    }
  }

  &--header-webinar {
    max-width: 300px;
    margin-left: auto;
    @include md {
      max-width: 300px;
      width: 100%;
      margin-top: 30px;
    }
    @include lg {
      max-width: 500px;
    }
    @include xl {
      max-width: 600px;
    }
  }

  &--header-white {
    text-align: center;
    margin-top: 40px;
    max-width: 400px;
    @include sm {
      max-width: 300px;
    }
    @include lg {
      max-width: 620px;
    }
    // @include xl {
    //   max-width: 600px;
    // }
  }
  &--descript-white {
    max-width: 288px;
    font-size: 14px;
    color: #fff;
    opacity: 0.7;
    margin-top: 20px;
    text-align: center;
    margin-bottom: 30px;
    span {
      display: block;
      margin-top: 30px;
      color: rgba(255, 255, 255, 0.7);
      font-weight: $weight__bold;
      margin-bottom: -30px;
    }
    @include md {
      margin-top: 30px;
      max-width: 600px;
    }
    @include lg {
      font-size: 20px;
    }
  }

  &--under-header {
    font-size: 16px;
    color: #404040;
    font-weight: 500;
    margin-top: 24px;
    max-width: 550px;
    text-align: left;
    line-height: 24px;
    @include md {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &--under-header-webinar {
    font-size: 14px;
    color: #3e3e3e;
    opacity: 0.7;
    margin-top: 40px;
    @include md {
      font-size: 16px;
      line-height: 20px;
    }
    @include lg {
      font-size: 20px;
      line-height: 26px;
    }
  }

  &__wrapperBlue {
    position: relative;
    // background-image: linear-gradient(#ff9d2f, #ff6126);
    background-color: #352fee;
    // clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 8vw));
    clip-path: polygon(0 0, 100% 10%, 100% 100%, 0 90%);
    padding: 0 20px;
    padding-bottom: 20px;
    @include md {
      clip-path: polygon(0 0, 100% 20%, 100% 100%, 0 80%);
    }
  }
  &__wrapperPartner {
    .section__security {
      padding: 0 20px;
      margin-top: 0;
    }
  }
}
.scrollSection {
  animation: slide1 1.5s ease-in-out infinite;
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 15px;
  margin-top: -160px;
  margin-bottom: 140px;

  &__webinar {
    position: relative;
    bottom: 320px;
  }
  &--homePage {
    margin-top: 100px;
    margin-bottom: 70px;
    @media (max-width: 769px) {
      margin-top: 40px;
    }
  }

  cursor: pointer;
  p {
    width: 30px;
    height: 10px;
  }
  img {
    margin-left: -10px;
    @include md {
      margin-left: -15px;
    }
  }
}

.page404 {
  &__wrapper {
    width: 100vw;
    height: calc(100vh - 77px);
    display: flex;
    justify-content: center;
    padding-top: 100px;
    @media (max-width: 1024px) {
      height: 100vh;
    }
    @media (max-width: 500px) {
      padding-top: 100px;
    }
  }
  &--paragraph {
    font-size: 21px;
    color: #000000;
    opacity: 0.19;
    font-weight: $weight__max;
    text-align: center;
    @media (max-width: 500px) {
      font-size: 18px;
    }
  }
  &__content {
    padding: 30px;
    img {
      width: 100%;
    }
  }
}
.expand-text {
  max-height: 10px;
  overflow: hidden;
  position: relative;
  transition: 0.4s;
  color: #4b4b4b7a !important;
  opacity: 1 !important;
  cursor: auto !important;
  &-login {
    max-height: 47px;
  }
  &-active {
    padding-bottom: 20px;
    max-height: 500px;
  }
  &--button {
    bottom: -6px;
    position: absolute;
    display: flex;
    justify-content: center;
    height: 20px;
    left: 0;
    width: 100%;
    text-align: center;
    background-color: #fff;
    &-gray {
      background-color: #f1f1f1;
    }
    &-txt {
      color: #000000;
      font-size: 10px;
      font-weight: $weight__max;
      cursor: pointer;
    }
    svg {
      height: 10px;
      width: 10px;
      margin-left: 2px;
    }
  }
}

.page404 {
  &__wrapper {
    width: 100vw;
    height: calc(100vh - 77px);
    display: flex;
    justify-content: center;
    padding-top: 100px;
    @media (max-width: 1024px) {
      height: 100vh;
    }
    @media (max-width: 500px) {
      padding-top: 100px;
    }
  }
  &--paragraph {
    font-size: 21px;
    color: #000000;
    opacity: 0.19;
    font-weight: $weight__max;
    text-align: center;
    @media (max-width: 500px) {
      font-size: 18px;
    }
  }
  &__content {
    padding: 30px;
    img {
      width: 100%;
    }
  }
}

.page500 {
  &__content {
    padding: 30px;
    img {
      width: 100%;
    }
  }
  &__messages {
    padding-top: 20px;
    &--paragraph {
      font-size: 20px;
      color: #707070;
      font-weight: 500;
      text-align: center;
      line-height: 30px;
      @media (max-width: 500px) {
        font-size: 18px;
      }
    }
  }
}

.tooltip > .tooltip-inner {
  background-color: #fff;
}
.tooltip {
  font-weight: 400 !important;
  border-radius: 7px !important;
  border-top-left-radius: 0px !important;
  font-family: $fontPoppins !important;
  font-size: 14px !important;
  background-color: #fff !important;
  color: #000 !important;
  padding: 20px !important;
  opacity: 1 !important;
  max-width: 300px;
  // box-shadow: 0px 3px 60px #0000001a;
  &--noShadow {
    box-shadow: none;
  }
}
.tooltip.place-bottom {
  margin-left: 136px !important;
  margin-top: 25px !important;
  &:after {
    border-left-color: transparent !important;
    border-left-width: 13px !important;
    border-right-width: 13px !important;
    border-bottom-width: 20px !important;
    top: -21px !important;
    left: 0 !important;
    margin-left: 0 !important;
  }
}

.heroSection {
  justify-content: space-between;
  margin: 0 75px 0 87px;
  @media (max-width: 769px) {
    margin: 0 16px;
    padding: 0;
    margin-top: 100px;
  }
  .section__l {
    margin-right: 100px;
    @media (max-width: 1500px) {
      margin-right: 0;
    }
  }
  .section__r {
    @media (max-width: 769px) {
      h1 {
        width: 100%;
        max-width: 100%;
      }
      .btn--wrapper {
        margin-top: 32px;
        width: 100%;
        display: flex;
        justify-content: center;
        .btn {
          width: 100%;
          justify-content: center;
        }
      }
      .paragraph {
        width: 100%;
        &--second {
          margin-bottom: 0;
        }
      }
    }
  }
}
.sectionWebinar {
  padding: 380px 30px 150px 30px;
  background-color: #fff;
  margin-top: -650px;
  clip-path: polygon(0 0, 100% 10%, 100% 100%, 0 96%);
  position: relative;
  @include md {
    padding: 380px 0 200px;
    clip-path: polygon(0 0, 100% 20%, 100% 100%, 0 80%);
  }
  @include lg {
    padding: 400px 0 300px;
  }
  .mainHeading {
    text-align: center;
    margin-bottom: 72px;
  }

  .corgi_paws-right {
    position: relative;
    bottom: 280px;
    right: 300px;
    @media (max-width: 1919px) {
      right: 0;
    }
  }

  span {
    font-size: 14px;
    text-align: center;
    color: #101010;
    width: 100%;
    display: block;
    margin: 30px 0 40px;
    font-weight: $weight__bold;
    opacity: 0.8;
    @include md {
      font-size: 16px;
    }
    @include lg {
      font-size: 20px;
    }
  }
}

.sectionProBono {
  background-color: #fff;
  clip-path: polygon(0 0, 100% 20%, 100% 100%, 0 80%);
  padding: 280px 0 100px 0;
  margin-top: -200px;

  @media (max-width: 1023px) {
    clip-path: polygon(0 0, 100% 10%, 100% 100%, 0 90%);
    padding: 180px 0 100px 0;
    height: 850px;
    margin-top: -100px;
  }
}

.hideFromUser {
  // display:none;
  background-color: #fff;
  width: 700px;
}

.showWebsite {
  color: unset;
  text-decoration: underline;
}

.imageInput {
  width: 287px;
  height: 149px;
  border: 1px dashed #707070;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-size: 10px;
  svg {
    margin: 0 !important;
  }
}

.imageAdded {
  display: flex;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  height: 37px;
  background-color: #d9d9d9;
  border-radius: 5px;
  color: #6b6b6b;
  font-weight: 500;
  font-size: 10px;
  svg {
    margin-left: 0 !important;
    margin-right: 6px !important;
    flex-shrink: 0;
  }
  &__name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  &__button {
    max-width: 50%;
    width: 100%;
  }
  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    .btn {
      border-radius: 4px;
      font-weight: 700;
      font-size: 10px;
      line-height: 15px;
      display: flex;
      align-items: center;
      text-align: center;
      width: 100%;
      text-align: center;
      justify-content: center;
      &--wrapper {
        width: 100%;
        &:nth-child(1) {
          margin-right: 16px;
          .btn {
            border: 1.5px solid #262626;
            background-color: transparent;
          }
        }
      }
    }
  }
}

.addLogoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 281px;
  height: 81px;
  border: 1px dashed #b9b9b9;
  border-radius: 5px;
}

.loader__resizer {
  .loader {
    padding-bottom: 60px;
  }
}
div.Toastify__toast {
  width: 296px;
  margin-left: auto;
  border-radius: 8px;
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.08);
  height: 86px;
  @media (max-width: 1024px) {
    width: calc(100% - 40px);
    margin-left: 20px;
  }
}
div.Toastify__toast-icon {
  width: 32px;
  height: 32px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  .svg {
    width: 32px;
    height: 32px;
  }
}
div.Toastify__toast-body {
  padding-inline: 12px;
  color: var(--czarny-300, #333);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
}
button.Toastify__close-button {
  margin-top: 8px;
  margin-right: 6px;
  opacity: 1;
  color: #a6a6a6;
}
.toast-title {
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 26px; /* 162.5% */
  letter-spacing: -0.04px;
  &--success {
    color: var(--zielony-700, #0f9958);
  }
  &--failure {
    color: var(--czerwony-500, #e20d0d);
  }
}

div .react-loading-skeleton {
  height: 18px;
  line-height: unset !important;
  border-radius: 40px;
}

.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}

.order-1 {
  order: 1;
}
.order--1 {
  order: -1;
}

.no-scroll {
  overflow: hidden;
}

.capitalize {
  text-transform: capitalize;
}

.fade-in {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
  z-index: 1;
}

.hidden {
  opacity: 0;
}

.slide-in {
  transition: 0.5s 0.5s ease-in-out;
}

.slide-in--left {
  animation: slideInLeft 1s forwards;
}

.slide-in--right {
  animation: slideInRight 1s forwards;
}

.slide-in--top {
  animation: slideInTop 1s forwards;
}

.slide-in--bottom {
  animation: slideInBottom 1s forwards;
}

@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInTop{
  from {
    transform: translateY(-50%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideInBottom{
  from {
    transform: translateY(50%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

div.Toastify__toast-container {
  z-index: 9999999999999999;
}