.userNumbersPhone {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  .heading {
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 32px;
  }
  .form {
    .form__field--label-error {
      position: absolute;
      font-size: 12px;
      min-width: 300px;
      margin-bottom: 2px;
    }
    input, .react-select__value-container {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      border:none;
      box-sizing: border-box;
      height: 100%;
    }
    .react-select, .react-select__control {
      height: 53px;
    }
    .react-select__control {
      margin-right: 8px;
      border:none;  
    }
    &__field {
      height: 53px;
      &:first-child {
        max-width: 121px !important;
      }
    }
    .react-select__clear-indicator, .react-select__indicator-separator {
      display: none;
    }
    .react-select__value-container {
      display: flex;
      justify-content: center;
    }
    .phone {
      max-width: 275px;
      display: flex;
      align-items: center;
      .react-select__control {
        margin-top: 0;
      }
    }
    .top {
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;  
      margin-bottom: 4px;
    }
  }
  .table {
    margin-top: 40px;
    &--left, &--right {
      width: 50%;
      display: flex;
      align-items: center;
    }
    &__row {
      display: flex;
      &:nth-child(n+2) {
        border-bottom: 0.5px solid #D9D9D9;
        padding: 16px 0;
      }
    }
    &--heading {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #666666;
      p:first-child {
        margin-left: 29px;
      }
    }
    &--lp {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #666666;
      margin-right: 25px;
    }
    &--name {
      color: #111111;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
    }
  }
  .buttons {
    display: flex;
    width: 100%;
    margin-top: 55px;
    justify-content: space-between;
    .btn--wrapper {
      width: 50%;
      max-width: 287px;
    }
    .btn {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}