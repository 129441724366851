.mainHeading {
  font-size: 48px;
  line-height: 56px;
  font-weight: $weight__max;
  position: relative;
  text-align: left;
  letter-spacing: -0.96px;

  @media (max-width: 1023px) {
    font-size: 36px;
    line-height: 40px;
    width: 255px;
    letter-spacing: -0.36px;
    
  }
  &--white {
    text-align: center !important;
  }
  &__content {
    max-width: 1600px;
  }
  .typewrite {
    color: #352fee;
    min-width: 380px;
    display: flex;
    margin-top: 10px;
    @media (max-width: 900px) {
      min-width: 280px;
      &--en,
      &--de {
        min-width: 200px;
      }
    }
    @media (max-width: 769px) {
      margin-top: 0;
      min-width: 185px;
    }
    &--en {
      min-width: 235px;
    }
    &--de {
      min-width: 235px;
    }
  }
  // .Typewriter__cursor {
  //   font-weight: 400;
  // }
  .paragraph {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    &--second {
      margin-bottom: -14px;
    }
  }
  &--img {
    position: absolute;
    transform: scaleX(-1);
    width: 40px;
    top: -54px;
    left: 247px;
    
    @media (max-width: 1419px) {
      left: 215px;
      top: -48px;
      width: 30px;
    }
    @media (max-width: 1024px) {
      left: 165px;
      top: -48px;
      width: 26px;
    }
    @media (max-width: 755px) {
      display: none;
    }
  }

  @include lg {
    max-width: 512px;
    margin-left: unset;
  }
  @include xl {
    max-width: 512px;
  }
  &--black {
    color: #101010;
  }
  &--white {
    color: #ffffff;
  }
}

.mainHeadingWebinar {
  @include xl {
    margin-top: 150px;
    line-height: 55px;
    font-size: 51px;
    max-width: 882px;
  }
  @media (max-width: 1023px) {
    width: 92%;
  }
}

.firstHeading {
  font-size: 49px;
  line-height: 65px;
  font-weight: $weight__max;
  @media (max-width: 1023px) {
    font-size: 20px;
    line-height: 27px;
    width: 255px;
  }
}

.signUpHeading {
  font-size: 49px;
  line-height: 65px;
  font-weight: $weight__max;
  @media (max-width: 1023px) {
    margin: 50px auto auto auto;
    font-size: 25px;
    line-height: 27px;
    width: 255px;
  }
}

.secondHeading {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  font-size: 35px;
  text-align: right;
  color: #352fee;
  @media (max-width: 1023px) {
    line-height: 35px;
    font-size: 18px;
    width: 255px;
  }
  &_time {
    margin-left: 10px;
    font-size: 30px;
    @media (max-width: 1023px) {
      font-size: unset;
    }
  }
}

.thirdHeading {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  font-size: 25px;
  text-align: right;
  color: #352fee;
  @media (max-width: 1023px) {
    font-size: 18px;
    width: 255px;
    line-height: 35px;
  }
}
.headingButton {
  width: 234px;
  height: 56px;
  justify-content: center;
  align-items: center;
}
