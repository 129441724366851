.select {
  &__option-container {
    display: flex;
    align-items: center;
    svg {
      margin-right: 10px;
      flex-shrink: 0;
    }
  }
}
.react-select {
  &--blocked {
    cursor: auto;
    pointer-events: none;
    .react-select__indicators {
      display: none;
    }
  }
}
.react-select__value-container {
  display: flex;
}
.react-select__placeholder {
  height: 100%;
  display: flex;
  align-items: center;
}

.systemUsers--filtering .react-select {
  padding: 4px 0;
  &__control {
    background-color: #f1f1f1;
    border: 0;
    border-radius: 5px;
    cursor: pointer;
    height: 34px;
    &--is-focused {
      border: 2px solid #e5e5e5;
      box-shadow: 0 0 0 1px#e5e5e5;
    }
    &:hover,
    &:focus-within {
      border: 0;
    }
  }
  &__indicators {
    height: 34px;
  }
}

.main-wrapper .language-select {
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  background-color: transparent;
  &__control {
    border-color: transparent;
    border-radius: 0;
    border-style: none;
    border-width: 0;
    background-color: transparent;
    &--is-focused,
    &:hover {
      border-color: transparent;
      box-shadow: none;
    }
  }
  &__control {
    width: 50px;
  }
  &__indicator {
    width: 16px;
    margin-left: -30px;
    margin-top: 4px;
    padding: 0;
    color: #000;
    &-separator {
      display: none;
    }
  }
  &__value-container {
    padding: 0;
  }
  .css-1wa3eu0-placeholder {
    color: #000;
  }
}

